import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index'
import { promiseAsyncAuto } from '../asyncAuto.js'
import { getSheinPicksGenerateBffParams } from '../bffParamsHandle/index.js'
import {
  goodsApiCache,
  selectionAndEntityFilterApiCache,
  selectionAndEntityCloudTagApiCache,
  sheinPicksNavTabApiCache,
  cccOperationApiCache
} from '../api/bffApi.js'
import { getCommonRequestQueue } from './common.js'
import { generateRealSelectBffFilterInfo } from '../utils'

const cccBffKey = 'ccc_config'

const getSheinPicksRequestQueue = ({ requestInfo, newSrcTabPageId }) => {
  const requestQueue = {
    // 一些公共请求（多语言等）
    ...getCommonRequestQueue({ requestInfo }),
    // picks tab数据
    navTabs: async(cb) => {
      const { navParams } = getSheinPicksGenerateBffParams(requestInfo, newSrcTabPageId)

      let bgBanner = {}
      let picksMenuList = []
      let modifiedQueryParams = {}
      let picksName = ''
      if (requestInfo.type === 'firstload') {
        const navTabRes = await sheinPicksNavTabApiCache.request({
          params: navParams
        })

        const { code, info } = navTabRes || {}

        bgBanner = info?.background || {}
        picksName = info?.picksLink?.[info?.pageId] || ''

        if (code === '0' && info?.categories?.length > 0) {
          picksMenuList = info.categories.map((item) => {
            return {
              adp: item.adp,
              id: item.cat_id,
              title: item.cat_name,
              image: item.image,
              hrefTarget: item.cat_id,
              hrefType: Number(item.type) === 1 ? 'realOne' : 'itemPicking',
              catChecked: true,
            }
          })

          const firstItem = picksMenuList[0]
          if (firstItem.hrefType === 'realOne') {
            modifiedQueryParams.sub_type = 'entity'
            modifiedQueryParams.cat_id = firstItem.id
            modifiedQueryParams.entity_id = firstItem.id
          } else {
            modifiedQueryParams.select_id = firstItem.id
            modifiedQueryParams.sub_type = 'selection'
          }

          modifiedQueryParams.adp = firstItem.adp
          modifiedQueryParams.hrefTarget = navTabRes.info?.pageId
          modifiedQueryParams.navId = firstItem.id
          modifiedQueryParams.picks_id = firstItem.id
        }
      }
      cb(null, picksMenuList.length > 0 ? { data: { bgBanner, picksMenuList, picksName }, modifiedQueryParams } : {})
    },
    // 商品列表
    goods: ['navTabs', async (result, cb) => {
      requestInfo.query = {  ...requestInfo.query, ...(result.navTabs.modifiedQueryParams || {}) }
      const { goodsParams } = getSheinPicksGenerateBffParams(requestInfo, newSrcTabPageId)
      const goodsData =  await goodsApiCache.request({
        type: 'sheinPicks',
        params: goodsParams,
        withoutAbort: requestInfo.query?.withoutAbort,
      })
      
      cb(null, goodsData)
    }],
    // 筛选
    filterData: ['navTabs', async(result, cb) => {
      requestInfo.query = {  ...requestInfo.query, ...(result.navTabs.modifiedQueryParams || {}) }
      const { filterParams, cloudTagParams } = getSheinPicksGenerateBffParams(requestInfo, newSrcTabPageId)
      if (requestInfo.type === 'nextpage') return cb(null, {})
      const filterResult = await promiseAsyncAuto({
        bffCateAttrResult: selectionAndEntityFilterApiCache.request(filterParams), // 分类/属性/tsp筛选
        bffCloudTagResult: selectionAndEntityCloudTagApiCache.request(cloudTagParams), // 标签云
      })
      cb(null, generateRealSelectBffFilterInfo(filterResult))
    }],
    cccOperation: ['navTabs', async (result, cb) => {
      requestInfo.query = {  ...requestInfo.query, ...(result.navTabs.modifiedQueryParams || {}) }
      const { cccOperationParams } = getSheinPicksGenerateBffParams(requestInfo, newSrcTabPageId)

      if (!cccOperationParams || requestInfo.type === 'nextpage') {
        return cb(null, {})
      }

      const sceneKeys = isSwitchBffApiVersion([cccBffKey])?.[cccBffKey] ? cccOperationParams.sceneKeys : ['mobile_seo_text']

      const res = await cccOperationApiCache.request({
        params: {
          cateId: cccOperationParams.form?.cate_id,
          cateType: cccOperationParams.form?.cate_type,
          sceneKey: sceneKeys.join(),
          listsId: '',
          isPreview: 0
        }
      })
      cb(null, res || {})
    }]
  }

  return requestQueue
}

export {
  getSheinPicksRequestQueue
}
